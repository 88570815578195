import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12')
];

export const server_loads = [2];

export const dictionary = {
		"/": [4],
		"/(app)/assistants": [5,[2]],
		"/(app)/backendtest": [~6,[2]],
		"/(app)/dashboard": [7,[2]],
		"/(public)/home": [12,[3]],
		"/(app)/material": [8,[2]],
		"/(app)/schedules": [9,[2]],
		"/(app)/settings": [10,[2]],
		"/(app)/student": [11,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';